import { useTranslation } from '@crehana/i18n';
import GoogleIcon from '@crehana/icons/GoogleIcon';
import Microsoft from '@crehana/icons/logos/Microsoft';
import { SimpleErrorBoundary } from '@crehana/layouts';
import { Tooltip } from '@crehana/ui';

import useFeatureFlagByNameKey from '@/shared/hooks/useFeatureFlagByNameKey/useFeatureFlagByNameKey';
import { AuthenticationProviderEnum } from '@/shared/types/graphql/globals.v5';
import { B2BLoginSocialButtons } from '@/views/Auth/components';

import { useSocialLoginMethods } from '../hooks/useSocialLoginMethods';

const ButtonPropertiesByProvider = {
  [AuthenticationProviderEnum.AUTHENTICATION_PROVIDER_GOOGLE]: {
    Icon: GoogleIcon,
    tooltipLabelKey: 'LOGIN_WITH_GOOGLE_SIGN_IN',
    tooltipLabelFallback: 'Ingresar con Google',
  },
  [AuthenticationProviderEnum.AUTHENTICATION_PROVIDER_MICROSOFT]: {
    Icon: Microsoft,
    tooltipLabelKey: 'LOGIN_WITH_MICROSOFT_SIGN_IN',
    tooltipLabelFallback: 'Ingresar con Microsoft',
  },
} as const;

export function SocialButtonsSection({
  organizationId,
  nextUrl,
  showOrDivider,
}: {
  organizationId: number | null;
  nextUrl: string;
  showOrDivider?: boolean;
}) {
  const { t } = useTranslation();

  const { loadingSocialLoginMethods, socialLoginMethods } =
    useSocialLoginMethods({ organizationId, nextUrl });

  const { isFlagEnabled: areSocialButtonsEnabled } = useFeatureFlagByNameKey({
    key: 'USER_LOGIN_SSO_BUTTONS',
    organizationId: organizationId,
  });

  if (
    loadingSocialLoginMethods ||
    (!socialLoginMethods.length && !areSocialButtonsEnabled)
  ) {
    return null;
  }

  return (
    <SimpleErrorBoundary>
      {socialLoginMethods.length ? (
        <B2BLoginSocialButtons
          className="mb-16"
          nextUrl={nextUrl}
          label={t('SOCIAL_LABEL')}
        >
          <div className="w-full flex">
            {socialLoginMethods.map(loginMethod => {
              const { Icon, tooltipLabelKey, tooltipLabelFallback } =
                // @ts-expect-error check with the code owner why the ButtonPropertiesByProvider doesn't have all the providers
                ButtonPropertiesByProvider[loginMethod.provider] ||
                ButtonPropertiesByProvider[
                  AuthenticationProviderEnum.AUTHENTICATION_PROVIDER_MICROSOFT
                ];

              return (
                <Tooltip
                  key={`login-method-${loginMethod.provider}`}
                  label={t(tooltipLabelKey, tooltipLabelFallback)}
                  position="bottom"
                  type="regular"
                >
                  <a
                    className="flex items-center justify-center w-56 h-48 bg-white rounded-5 shadow-4-dp"
                    href={loginMethod.method_url}
                  >
                    <Icon size={28} />
                  </a>
                </Tooltip>
              );
            })}
          </div>
        </B2BLoginSocialButtons>
      ) : (
        <B2BLoginSocialButtons
          className="mb-16"
          nextUrl={nextUrl}
          label={t('SOCIAL_LABEL')}
        />
      )}

      {showOrDivider && (
        <div className="flex items-center">
          <div className="flex flex-grow h-1 bg-gray-main"></div>
          <span className="tw-font-body4 mx-32">{'O'}</span>
          <div className="flex flex-grow h-1 bg-gray-main"></div>
        </div>
      )}
    </SimpleErrorBoundary>
  );
}
